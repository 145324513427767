import React, { useContext, useEffect } from "react"
import { graphql } from "gatsby"
import { ParallaxProvider } from "react-scroll-parallax"

import {
  GlobalDispatchContext,
  GlobalStateContext,
} from "../context/GlobalContextProvider"
import socialImage from "../../static/social-share/DMITRIEVA-ART-social-share.jpg"
import SEO from "../components/seo"
import PicturesGrid from "../components/Galleries/PicturesGrid"
import AddressGrid from "../components/Galleries/AddressGrid"

const GalleriesPage = ({ data, location }) => {
  const content = data.prismicGalleriespage.data
  const dispatch = useContext(GlobalDispatchContext)
  const state = useContext(GlobalStateContext)
  const toggleTransition = state.toggleTransition
  const path = location.pathname
  const langSliced = path.slice(1, 3)
  useEffect(() => {
    if (toggleTransition) {
      setTimeout(() => {
        dispatch({ type: "TOGGLE_TRANSITION" })
      }, 600)
    }
  }, [])
  return (
    <>
      <ParallaxProvider>
        <SEO
          title={
            langSliced === "fr"
              ? "Galeries"
              : langSliced === "ru"
              ? "Галереи"
              : "Galleries"
          }
          image={socialImage}
          lang={langSliced}
          path={path}
        />
        <AddressGrid content={content} />
        <PicturesGrid content={content} />
      </ParallaxProvider>
    </>
  )
}

export default GalleriesPage

export const query = graphql`
  query GalleriesPage($lang: String!) {
    prismicGalleriespage(lang: { eq: $lang }) {
      data {
        page_title
        galleries {
          gallery_name
          city
          country
          gallery_address
          gallery_instagram_label
          gallery_instagram_url
        }
        pictures {
          picture {
            fluid {
              src
              ...GatsbyPrismicImageFluid
            }
          }
        }
      }
    }
  }
`
