import React from "react"
import ScrollAnimation from "react-animate-on-scroll"
import Img from "gatsby-image"

import "./styles.scss"

const PicturesGrid = ({ content, location }) => {
  return (
    <section className="galleriesPictures">
      <ul className="galleriesPictures-grid">
        {content.pictures.map(item => (
          <li key={item.picture.fluid.src}>
            <ScrollAnimation
              animateIn="slideIn"
              duration={1}
              delay={100}
              animateOnce
            >
              <Img fluid={{ ...item.picture.fluid, aspectRatio: 1 }} />
            </ScrollAnimation>
          </li>
        ))}
      </ul>
    </section>
  )
}

export default PicturesGrid
