import React from "react"

import "./styles.scss"

const AddressGrid = ({ content, location }) => {
  return (
    <>
      <section className="galleriesAddress">
        <div className="mobile-header" />
        <h1 className="galleriesAddress-title">{content.page_title}</h1>
        <ul className="galleriesAddress-grid">
          {content.galleries.map(item => {
            return (
              <li key={item.gallery_name}>
                <article>
                  <h2>{item.gallery_name}</h2>
                  {item.gallery_address && <p>{item.gallery_address}</p>}
                  <p>{item.city}</p>
                  <p>{item.country}</p>
                  {item.gallery_instagram_url && (
                    <a
                      href={item.gallery_instagram_url}
                      target="_blank"
                      rel="noreferrer"
                    >
                      {item.gallery_instagram_label}
                    </a>
                  )}
                </article>
              </li>
            )
          })}
        </ul>
      </section>
    </>
  )
}

export default AddressGrid
